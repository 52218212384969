<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import UserCard from "@/components/ui/MA/trainings/Courses/UserCard.vue";
// import CourseTimeline from "@/components/ui/MA/trainings/Courses/CourseTimelineForUser.vue";
// import RedarChart from "@/components/ui/MA/trainings/Courses/RedarChart.vue";
// import DonoughtChart from "@/components/ui/MA/trainings/Courses/DonoughtChart.vue";
import LineChart from "@/components/ui/MA/trainings/Courses/LineChart.vue";
import TableData from "@/components/ui/MA/appraisals/TableData.vue";
import UserAddRemoveCourses from "@/components/ui/MA/trainings/Courses/UserAddRemoveCourses.vue";
import {
  FETCH_ALL_COURSES_ACTION,
  FETCH_SINGLE_USER,
  SET_SINGLE_USER_GETTER,
  ENROLL_A_USER_TO_COURSE,
} from "@/store/modules/MA/staff-trainings/constants";

const store = useStore();
const route = useRoute();

const router = useRouter();

const userData = computed(() => {
  return store.getters[`Trainings/${SET_SINGLE_USER_GETTER}`];
});

const UserCourses = computed({
  get() {
    return userData.value.courses || [];
  },
  set(value) {
    userData.value.courses = value;
  },
});
const tabledropdownOptions = [
  {
    type: "link",
    linkName: "ma-view-a-course",
    paramName: "id",
    label: "View",
  },
];

const tableHeader = [
  { title: "Course Name", prop: "name", id: "name" },
  { title: "Assigned By", prop: "assigned_by", id: "assigned_by" },
  { title: "Assigned On", prop: "assigned_on", id: "assigned_on" },
  { title: "Due By", prop: "due_date", id: "due_date" },
  { title: "Status", prop: "status", id: "statusCourse" },
  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];

onMounted(async () => {
  await store.dispatch(`Trainings/${FETCH_ALL_COURSES_ACTION}`);
  await store.dispatch(`Trainings/${FETCH_SINGLE_USER}`, {
    user: route.params.id,
  });
  chartKey.value = +1;
});

// const dataForTable = computed(() => {
//   return store.getters[`Trainings/${GET_COURSES_GETTER}`];
// });

const ShowEditAssignedCoursesModal = ref(false);

const submit = async () => {
  await store.dispatch(`Trainings/${ENROLL_A_USER_TO_COURSE}`, fd);
  const courses = UserCourses.value.map((item) => ({ course: item.id }));
  const fd = {
    user: route.params.id,
    courses,
  };
};

const labelsArray = ["In-progress", "Completed", "Overdue"];
const chartDataArray = [
  userData?.value?.in_progress_courses_count,
  userData?.value?.completed_courses_count,
  userData?.value?.overdue_courses_count,
];
const chartKey = ref(0);

const reRoute = () => {
  router.back();
};
</script>
<template>
  <div class="flex gap-5 w-full p-5">
    <div class="w-1/4">
      <div
        class="flex items-center text-teal ml-5 mb-3 cursor-pointer w-24"
        @click="reRoute"
      >
        <span class="material-icons-outlined"> arrow_back </span>
        go Back
      </div>
      <UserCard :userData="userData" />
    </div>
    <div class="flex-1 flex flex-col mt-9">
      <div :key="chartKey" class="flex-1 px-5 bg-gray-200 rounded-md">
        <p class="text-gray-600 font-medium mb-4 text-xl">
          Course Completion Progress {{ chartDataArray }}
        </p>
        <LineChart :labelsArray="labelsArray" :dataArray="chartDataArray" />
      </div>
      <div class="flex-1 overflow-y-auto px-5 bg-gray-200 rounded-md my-4">
        <div class="flex justify-between items-center">
          <p class="text-gray-600 font-medium mb-4 text-xl">Assigned Courses</p>
          <!-- <el-dropdown>
            <span class="material-icons"> more_horiz </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item> View Course Info </el-dropdown-item>
                <el-dropdown-item
                  @click="
                    () => {
                      ShowEditAssignedCoursesModal = true;
                    }
                  "
                >
                  Edit Assigned Courses
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
        </div>
        <TableData
          :dropdownOptions="tabledropdownOptions"
          :tableData="userData?.courses || []"
          :tableHeader="tableHeader"
          :rowClass="'text-center'"
        />
      </div>
    </div>
    <base-modal v-model:show="ShowEditAssignedCoursesModal" @confirm="submit">
      <template #title>Add/Remove Training Courses</template>
      <template #content>
        <UserAddRemoveCourses v-model:userCourses="UserCourses" />
      </template>
      <template #successButton> Okay </template>
    </base-modal>
  </div>
</template>