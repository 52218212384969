<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import {
  FETCH_ALL_COURSES_ACTION,
  GET_COURSES_GETTER,
  GET_COURSES_TOTAL_GETTER,
} from "@/store/modules/MA/staff-trainings/constants";

const emits = defineEmits(["update:userCourses"]);
const props = defineProps({
  userCourses: {
    type: Array,
    default: () => [],
  },
});
const store = useStore();

const all_training_courses = computed(() => {
  return store.getters[`Trainings/${GET_COURSES_GETTER}`];
});
const total_courses = computed(() => {
  return store.getters[`Trainings/${GET_COURSES_TOTAL_GETTER}`];
});

const change = async (page) => {
  await store.dispatch(`Trainings/${FETCH_ALL_COURSES_ACTION}`, page);
};
const selected_course = ref();

const addCourseToArray = (item) => {
  selected_course.value = item;
};

const userCourses = computed({
  get() {
    return props.userCourses;
  },
  set(value) {
    emits("update:userCourses", value);
  },
});

const addItemToUserCourses = () => {
  userCourses.value = Array.from(
    new Set([...userCourses.value, selected_course.value])
  );
};

const remvoeItemsFromUsersCourses = () => {
  userCourses.value = userCourses.value?.filter(
    (item) => item.id !== selected_course.value.id
  );
};
</script>
<template>
  <div class="flex gap-5">
    <div class="flex-grow max-h-520">
      <div class="flex flex-col h-full bg-gray-300 rounded-md p-5">
        <p class="text-md font-medium text-gray-600">
          Available Training Courses
        </p>
        <div class="flex-1 h-full overflow-auto">
          <div class="flex flex-col">
            <div
              class="
                bg-white
                rounded-md
                mb-2
                p-3
                cursor-pointer
                border border-gray-400
              "
              :class="{ 'border-teal': selected_course?.id === item.id }"
              v-for="item in all_training_courses"
              :key="item"
              @click="
                () => {
                  addCourseToArray(item);
                }
              "
            >
              <p class="text-teal">
                {{ item.name }}
              </p>
              <div class="text-gray-600 ml-10">{{ item.description }}</div>
            </div>
          </div>
        </div>
        <div class="flex justify-center my-5">
          <el-pagination
            background
            layout="pager"
            :total="total_courses"
            @current-change="change"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center text-center w-32">
      <span
        @click="addItemToUserCourses"
        class="
          bg-teal
          mb-3
          rounded-md
          text-white text-center
          px-3
          py-1
          cursor-pointer
        "
        >Add >></span
      >
      <div
        @click="remvoeItemsFromUsersCourses"
        class="
          bg-orange
          mt-2
          rounded-md
          text-white text-center
          px-3
          py-1
          cursor-pointer
        "
      >
        &lt;&lt; remove
      </div>
    </div>
    <div class="flex-grow max-h-520">
      <div class="flex flex-col h-full bg-gray-300 rounded-md p-5">
        <p class="text-md font-medium text-gray-600">
          Enrolled Training Courses
        </p>
        <div class="flex-1 h-full overflow-auto">
          <div class="flex flex-col">
            <div
              class="
                bg-white
                rounded-md
                mb-2
                p-3
                cursor-pointer
                border border-gray-400
              "
              :class="{ 'border-orange': selected_course?.id === item.id }"
              v-for="item in userCourses"
              :key="item"
              @click="
                () => {
                  addCourseToArray(item);
                }
              "
            >
              <p class="text-teal">
                {{ item.name }}
              </p>
              <div class="text-gray-600 ml-10">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>