<script setup>
import { defineProps, computed } from "vue";
const props = defineProps({
  userData: {
    type: Object,
    default: () => ({}),
  },
});

const userDetails = computed(() => {
  return [
    {
      title: "Location",
      value: props.userData.profile?.city,
      icon: "location_on",
      icon_class: "material-icons-outlined",
    },
    {
      title: "Contact Number",
      value: props.userData.profile?.mobile_phone,
      icon: "phone",
      icon_class: "material-icons-outlined",
    },
    {
      title: "Email",
      value: props.userData.email,
      icon: "mail",
      icon_class: "material-icons-outlined",
    },
    {
      title: "NHS Card Number",
      value: props.userData.profile?.nhs_number,
      icon: "credit_card",
      icon_class: "material-icons-outlined",
    },
    {
      title: "Job Title",
      value: props.userData?.position_summary?.job_title,
      icon: "work",
      icon_class: "material-icons-outlined",
    },
    {
      title: "Contract Type",
      value: props.userData?.position_summary?.contract_type,
      icon: "gavel",
      icon_class: "material-icons-outlined",
    },
  ];
});
</script>
<template>
  <div class="px-5 py-3 bg-gray-200 rounded-md">
    <div class="flex justify-between">
      <p class="text-gray-600 font-medium">Personal Profile</p>
      <div class="cursor-pointer">
        <span class="material-icons-outlined"> more_vert </span>
      </div>
    </div>
    <div class="w-full flex justify-center flex-col items-center">
      <span class="material-icons text-9xl text-orange"> account_circle </span>
      <p>
        {{
          props.userData?.profile?.first_name +
            " " +
            props.userData?.profile?.last_name || "User Name"
        }}
      </p>
    </div>
    <div class="w-full flex flex-col">
      <div
        class="flex w-full items-center"
        v-for="item in userDetails"
        :key="item"
      >
        <span class="material-icons-outlined mr-3 text-4xl text-teal">
          {{ item.icon }}
        </span>
        <div class="flex-grow">
          <div class="flex-col flex">
            <p class="text-teal">{{ item.title }}</p>
            <p class="text-gray-500">
              {{ item.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>