<template>
  <div class="flex w-full">
    <div class="w-3/4">
      <vue3-chart-js
        :id="barChart.id"
        :type="barChart.type"
        :data="barChart.data"
        :options="barChart.options"
      ></vue3-chart-js>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: "App",
  components: {
    Vue3ChartJs,
  },
  props: {
    labelsArray: {
      type: Array,
      required: true,
    },
    dataArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      barChart: {
        id: "bar",
        type: "bar",
        data: {
          labels: this.labelsArray, // ["In-progress", "Completed", "Overdue"],
          datasets: [
            {
              label: "User's Courses Progress",
              barThickness: 100,
              data: this.dataArray, //[65, 59, 65],
              backgroundColor: [
                "rgba(255, 205, 86, 0.2)",
                "teal",
                "rgba(255, 99, 132, 0.2)",
              ],
              borderColor: ["rgb(255, 159, 64)", "teal", "rgb(255, 99, 132)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {},
        },
      },
    };
  },
};
</script>